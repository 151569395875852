import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'
import { Select } from 'antd';
import './index.css';
import ImageBG from '/images/wepik-photo-mode-2022625-112124.png';
import imageFSQL1 from '/images/fsql-1.png';
import imageFSQL2 from '/images/fsql-2.png';
import imageFSQL3 from '/images/fsql-3.png';
import imageFSQL4 from '/images/fsql-4.png';
import imageFSQL5 from '/images/fsql-5.png';
import imageFSQL6 from '/images/fsql-6.png';
import imageFSQL7 from '/images/fsql-7.png';
import imageFSQL8 from '/images/fsql-8.png';
import imageFSQL9 from '/images/fsql-9.png';

import _province from "../templates/election_map/model/_provinces.json";
import Calculate from "../util/calculate";

import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'
import ENG_Addrees from '../util/ENG_Addrees'
import useTranslation from '../hook/useTranslation'
import api from '../util/api'
import getProvinceName from '../util/getProvinceName'

const { Option } = Select;

const border_color = ["#1b5e20", "#388e3c", "#66bb6a", "#a5d6a7", "#94a3b8"];

const _genColorRange = (color_set, value) => {
  color_set = color_set.sort((a, b) => b.value - a.value);
  let color = "";
  for (let i in color_set) {
    if (value > color_set[i].value) {
      color = color_set[i].color;
      break;
    }
  }
  return color;
};

const getDataMap = (data) => {

  var result = [];

  data.reduce(function (res, value) {
    if (!res[value.provinceid]) {
      res[value.provinceid] = { provinceid: value.provinceid, province: value.province, school: 0 };
      result.push(res[value.provinceid]);
    }
    res[value.provinceid].school += 1;
    return res;
  }, {});



  _province.forEach(item => {

    let tmp = result.find(x => x.provinceid === item.id.toString())

    if (!tmp) {

      tmp = { provinceid: item.id, province: item.name, school: 0 }
      result.push(tmp)
    }

  });



  let resultMap = result.map((item, idx) => {
    return { ...item, ...{ color: "#cfcfcf" } }
  });


  resultMap = resultMap.map((item, idx) => {
    return {
      ...item,
      ...{ percent: (item.school / data.length) * 100 },
    };
  });

  let data_color = [
    {
      color: border_color[0],
      value: Calculate.Percentile(
        90,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `มาก`,
    },
    {
      color: border_color[1],
      value: Calculate.Percentile(
        50,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `ปานกลาง`,
    },
    {
      color: border_color[2],
      value: Calculate.Percentile(
        10,
        resultMap.map((item) => item.school)
      ),
      count: 0,
      text: `ปานกลาง`,
    },
    { color: border_color[3], value: 0, count: 0, text: `น้อย` },
    { color: border_color[4], value: -100, count: 0, text: `น้อย` },
  ];



  resultMap = resultMap.map((item, idx) => {

    return {
      ...item,
      ...{
        province_name: item.province,
        city: item.city_name ? item.city_name : null,
        percent: parseFloat(item.percent.toFixed(2)),
        color: _genColorRange(data_color, item.school),
      },
    };
  });



  console.log("resultMap", resultMap);


  return resultMap



}

const PageTPS = (props) => {

  const [data, setData] = useState([])
  const [school, setSchool] = useState([])
  const [data_map, setDataMap] = useState([])
  const [T, setLang, lang] = useTranslation()
  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2563",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  // useEffect(async () => {
  //   let res_fsql = await api.getInitData('fsql')
  //   let is_login = api.isLogin().status
  //   let res_profile = await (is_login ? api.getProfileDb() : null)
  //   if (res_profile) {
  //     if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
  //       let pid = res_profile?.profile?.profile?.provinceId
  //       setData(res_fsql.deepdata.data.filter(item => item.province === getProvinceName(pid)))
  //       setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
  //     } else {
  //       setData(res_fsql.deepdata.data)
  //     }
  //   }
  // }, []);

  useEffect(async () => {
    let res_fsql = await api.getInitData('fsql')
    setData(res_fsql.deepdata.data)
  }, [])

  useEffect(() => {
    if (data) {
      let dataMap = getDataMap(data)
      setDataMap([
        { id: "border", layer: 'country', data: dataMap },
        { id: 'school', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 1), icon: marker_obec },
        { id: 'school2', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 2), icon: marker_bpp },
        { id: 'school3', layer: 'marker', data: data.map(item => ({ ...item, type: 'school' })).filter(x => x.kmgroup === 3), icon: marker_opec }
      ])
      setSchool(data.length)

      let data_legend = []
      if (lang === 'EN') {
        data_legend = [
          { name: 'Number of Schools', value: dataMap.reduce((acc, cur) => acc + cur.school, 0), unit: 'schools' },
        ]
      } else {
        data_legend = [
          { name: 'จำนวนโรงเรียน', value: dataMap.reduce((acc, cur) => acc + cur.school, 0), unit: 'โรงเรียน' },
        ]
      }
      // จำนวนโรงเรียน
      setLegendData({
        ...legend_data,
        title: ENG_Addrees(filter.province_name, lang),
        subtitle: ENG_Addrees(filter.city_name, lang),
        data: data_legend,
      })
    }
  }, [data, lang])

  return (
    <Layout Role={['public-login', 'partner', 'eef', 'partner_province']} backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("FSQL_Project_TITLE_1")}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("FSQL_Project_TITLE_2")}</h1>

        <div className="max-w-[1300px] 2xl:max-w-[1400px] rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Project_Description")}</div>

          <p className="text-xl mb-2 font-baijam" > {T("FSQL_Project_Description")}</p>

          <div className="grid grid-cols-11 gap-x-4 gap-y-8 w-full mt-8" >
            <div className="col-span-11 md:col-span-8 lg:col-span-4">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Target_Group")}</div>
              < div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min md:h-[300px] p-3" >
                <div className='flex flex-col justify-around '>
                  <div className="flex flex-col">
                    <img className="flex self-center" src={imageFSQL1} alt="image" width={'84px'} style={{}} />
                  </div>
                  <div className="flex-col self-center">
                    <div className="text-lg lg:text-xl my-2 font-bold text-center">โรงเรียนครูรัก(ษ์)ถิ่นที่เข้าร่วมโครงการ</div>
                    <div className="text-2xl mb-2 font-bold text-center">{school} {T("school")}</div>
                    < div className="text-md mb-2 font-baijam" > {T("FSQL_Project_Target_Group")}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-11 lg:col-span-7">
              <div className="font-bold text-xl mb-2 text-[#0E3165] mt-8 md:mt-0">{T("FSQL_HOW_TO_DEVELOP")}</div>
              < div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min md:h-[300px] p-3" >
                <div className="flex flex-col">
                  <div className='flex flex-col md:flex-row justify-center mt-[20px]'>
                    <div className='flex flex-row justify-center'>
                      <img className="flex self-center h-[60px] xl:h-[75px] mx-5 my-2" src={imageFSQL2} alt="images" />
                      <img className="flex self-center h-[60px] xl:h-[75px] mx-5 my-2" src={imageFSQL3} alt="image" />
                      <img className="flex self-center h-[60px] xl:h-[75px] mx-5 my-2" src={imageFSQL5} alt="image" />
                    </div>
                    <img className="flex self-center h-[60px] xl:h-[75px] mx-5 my-2" src={imageFSQL4} alt="image" />
                  </div>
                  <div className="text-md my-4 font-baijam">{T("FSQL_HOW_TO_DEVELOP_Description")}</div>
                </div>
              </div>
            </div>

            <div className="col-span-11">
              <div className="font-bold text-xl mb-2 text-[#0E3165] mt-10 md:mt-0">{T("FSQL_4Key")}</div>
              < div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'> md:h-[500px] lg:h-[330px] p-1" >
                <div className="text-xl mb-2 p-2 ">{T("FSQL_4Key_Description")}</div>
                <div className="grid grid-cols-8 gap-x-4 gap-y-4 w-full mt-4">
                  <div className="col-span-8 md:col-span-4 lg:col-span-2  flex flex-col justify-center">
                    <img className="flex self-center h-[100px] xl:h-[125px] mx-5 my-2" src={imageFSQL6} alt="images" />
                    <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{T("FSQL_Quality_of_Educational_Management")}</div>
                  </div>
                  <div className="col-span-8 md:col-span-4 lg:col-span-2  flex flex-col justify-center">
                    <img className="flex self-center h-[100px] xl:h-[125px] mx-5 my-2" src={imageFSQL7} alt="images" />
                    <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{T("FSQL_Appropriate_numbers_and_Quality_of_teachers")}</div>
                  </div>
                  <div className="col-span-8 md:col-span-4 lg:col-span-2  flex flex-col justify-center">
                    <img className="flex self-center h-[100px] xl:h-[125px] mx-5 my-2" src={imageFSQL8} alt="images" />
                    <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{T("FSQL_Educational_outcomes")}</div>
                  </div>
                  <div className="col-span-8 md:col-span-4 lg:col-span-2  flex flex-col justify-center">
                    <img className="flex self-center h-[100px] xl:h-[125px] mx-5 my-2" src={imageFSQL9} alt="images" />
                    <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{T("FSQL_Student_support_and_infrastructure_systems")}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="max-w rounded-xl overflow-hidden shadow-card bg-white w-full mt-20 md:mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2 text-[#048967] text-center">
                  {T("FSQL_MAP")}
                </p>

                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-fsql')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>

              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>


            <div className="col-span-11 justify-right">
              <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                <Mapbox
                  id={'map-fsql'}
                  filter={filter}
                  data={data_map}
                  className="fullmap-cct-container"
                  style={"border_basic"}
                  lang={lang}
                />
                <div className='absolute top-[10px] left-[10px] z-1'>
                  <LegendCard {...legend_data} lang={lang} />
                </div>
                <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                  <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("FSQL_SCHOOL")}</span>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T('MAX')}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T('MIN')}
                        </div>
                      </div>
                      <div className='ml-4  flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T('NO_DATA')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='absolute bottom-[10px] left-[470px] z-1'>
                  <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("FSQL_SCHOOL_TYPE")}</span>
                    <div className="flex flex-row">
                      <div className="flex flex-row text-center mr-2">
                        <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                        <span className='center m-auto'>Advantaged.</span>
                      </div>
                      <div className="flex flex-row text-center mr-2">
                        <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                        <span className='center m-auto'>Average.</span>
                      </div>
                      <div className="flex flex-row text-center mr-2">
                        <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                        <span className='center m-auto'>Disadvantaged.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <RefDataDate
            title={T("FSQL_REF")}
            subjectTitle={T('PROCESSED_APR_2565')}
          />
        </div>

        <div className='h-[120px]'></div>
      </div >
    </Layout >
  )
}

export default PageTPS
