function lessThanZeroError(p) {
  return 'Expect percentile to be >= 0 but given "' + p + '" and its type is "' + (typeof p) + '".';
}

function greaterThanHundredError(p) {
  return 'Expect percentile to be <= 100 but given "' + p + '" and its type is "' + (typeof p) + '".';
}

function nanError(p) {
  return 'Expect percentile to be a number but given "' + p + '" and its type is "' + (typeof p) + '".';
}

const Percentile = (p, list, fn) => {
  // if (isNaN(Number(p))) {
  //   throw new Error(nanError(p));
  // }

  p = Number(p);

  // if (p < 0) {
  //   throw new Error(lessThanZeroError(p));
  // }

  // if (p > 100) {
  //   throw new Error(greaterThanHundredError(p));
  // }

  list = list.slice().sort(function (a, b) {
    if (fn) {
      a = fn(a);
      b = fn(b);
    }

    a = Number.isNaN(a) ? Number.NEGATIVE_INFINITY : a;
    b = Number.isNaN(b) ? Number.NEGATIVE_INFINITY : b;

    if (a > b) return 1;
    if (a < b) return -1;

    return 0;
  });

  if (p === 0) return list[0];

  var kIndex = Math.ceil((p / 100) * (list.length + 1));
  return list[kIndex];
}

export default {
  Percentile
}