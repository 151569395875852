import React, { useState, useEffect } from 'react';
import './index.css';


const LegendCard = (props) => {
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(() => {
    setLegendData({
      title: props.title,
      subtitle: props.subtitle,
      data: props.data,
      footer: props.footer
    })
  }, [props])

  return (
    <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
      <span className="text-lg font-bold">{props?.lang === 'EN' ? 'Province: ' : 'จังหวัด: '}
        < span className="text-xl text-[#048967]">
          {legend_data.title}
        </span>
      </span>
      {
        legend_data.subtitle && legend_data.subtitle !== '' &&
        (
          <span className="text-lg font-bold">{props?.lang === 'EN' ? 'district: ' : 'อำเภอ: '
          }
            <span className="text-xl text-[#048967]">
              {legend_data.subtitle}
            </span>
          </span>
        )
      }
      {
        legend_data.data.map(item => {
          return (
            <div className="grid grid-cols-3 text-base">
              <div className="col-span-2 pl-4">
                <span className="pr-2 pb-1">{`${item.name} : `}</span>
              </div>
              <div className="col-span-1 text-right text-lg font-bold">
                <span className="pr-2 pb-1">{`${item.value.toLocaleString("en-US")} ${item.unit}`}</span>
              </div>
            </div>
          )
        })
      }
      <div className="w-auto text-sm font-bold text-[#3b69df] break-words">
        {legend_data.footer}
      </div>
    </div >
  )
}

export default LegendCard
