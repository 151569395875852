import React from 'react'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function RefDataDate({ title = '', subjectTitle = '' }) {
  return (
    <div>
      <p className='p-0 m-0 mt-4'>{title}</p>
      <p className='p-0 m-0'>{subjectTitle}</p>
    </div>
  );
}

