import { useState, useEffect,useCallback } from "react";
import ENG_Addrees from '../util/ENG_Addrees'

export default function useTranslation(init_lang='TH') {
    let [lang, setLang] = useState(init_lang);
    const T = useCallback((text) => {
        return ENG_Addrees(text,lang)
     }, [lang]);
    return [T ,setLang , lang];
}
